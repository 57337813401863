@import '../../../../scss/theme-bootstrap';

.gnav-section-group {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 50px;
  @include breakpoint($landscape-only) {
    padding: 15px $container-pad-landscape;
    margin-bottom: 2px;
    flex-direction: column;
    height: auto;
  }
  // Mobile specific expanded state with parent group expanded
  .gnav-mobile-group-active & {
    padding-#{$ldirection}: 0;
    @include breakpoint($landscape-only) {
      padding: 0 $container-pad-landscape 0 $container-pad-landscape;
    }
  }
  &__header {
    @include gnav-section-title;
    color: $color-white;
    padding-#{$ldirection}: $container-pad-small;
    padding-bottom: 8px;
    @include breakpoint($landscape-only) {
      padding-#{$ldirection}: 0;
      padding-bottom: 10px;
    }
    label {
      .active-section-group & {
        box-shadow: 0 1px 0 0 $color-white;
      }
    }
  }
  &__section {
    padding: 4px 0;
    @include breakpoint($landscape-only) {
      padding: 0;
    }
  }
  &__static-link {
    @include gnav-category-link();
    @include swap_direction(padding, 4px 0 4px $container-pad-small);
    .gnav-static-link {
      @include gnav-category-link();
      display: inline;
    }
    @include breakpoint($landscape-only) {
      padding: 0;
    }
    input[type='radio'] ~ label {
      &::before {
        display: none;
      }
    }
    &,
    a.gnav-static-link {
      @include breakpoint($landscape-only) {
        color: $color-white-op50;
      }
      &:hover {
        @include breakpoint($landscape-only) {
          color: $color-white;
        }
      }
    }
  }
  // expanded state
  .site-header-formatter__subsection-trigger-reset:checked
    ~ .site-header-formatter__sections
    .header-nav-section__trigger:checked
    + .header-nav-section__section
    & {
    & > div:nth-child(1) {
      .gnav-section-formatter__content-wrapper {
        @include breakpoint($landscape-only) {
          opacity: 1;
          flex-direction: column;
          justify-content: space-between;
        }
      }
      .gnav-section-formatter__link {
        @include breakpoint($landscape-only) {
          font-weight: bold;
        }
      }
    }
  }
  .gnav-section-formatter__trigger:checked ~ .gnav-section-formatter__content-wrapper {
    display: block;
    @include breakpoint($landscape-only) {
      visibility: visible;
      display: flex;
      transform: unset;
      opacity: 1;
      z-index: 2;
      #{$ldirection}: $gnav-first-panel-width;
      width: 23.96%;
    }
  }
  &.gnav-section-group--enable-blank-space {
    .gnav-section-group--space {
      height: $gnav-mobile-link-height;
      .site-header-formatter__subsection-trigger-reset:not(:checked) ~ .site-header-formatter__sections & {
        display: none;
        @include breakpoint($landscape-only) {
          display: block;
        }
      }
    }
    @for $i from 1 through 10 {
      :not(.gnav-section-group--space).gnav-section-group__section:nth-child(#{$i}) {
        order: $i;
      }
      &.gnav-section-group--break-after-#{$i} .gnav-section-group--space {
        order: $i;
      }
    }
  }
  // Styles for Gnav Section Formatter Content
  .gnav-section-formatter {
    &__content-wrapper {
      .site-header-formatter__subsection-trigger-reset:not(:checked)
        ~ div
        .gnav-section-formatter__trigger:not(:checked)
        + & {
        display: none;
        @include breakpoint($landscape-only) {
          display: block;
        }
      }
    }
    &__link {
      @include breakpoint($landscape-only) {
        display: inline-flex;
      }
    }
    &__link-label {
      @include breakpoint($landscape-only) {
        z-index: 2;
      }
    }
  }
  &:last-child {
    margin-bottom: 20px;
    @include breakpoint($landscape-only) {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}
